/* eslint-disable jsx-a11y/no-onchange */

import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { isEmpty } from "lodash"

const Market = ({
  marketReportContent,
  marketReportHeader,
  marketReportSubheader,
  showLearnMore,
  communityKey = 'SILVERTHORNE'
}) => {
  const data = useStaticQuery(graphql`
    query QueryMarket {
      allCommunities(sort: {fields: marketKey, order: ASC}) {
        nodes {
          marketKey
          name
          path
          marketResult {
            closedTotalSales
            communitySalesCount
            pricePerSquareTotal
            propertyWithZeroSquareFoot
            totalActiveListing
            totalNewProperties
            totalPendingListing
            communityAverageSalesPrice
            pricePerSquareFoot
          }
        }
      }
    }
  `)

  const [community, setCommunity] = useState(data.allCommunities.nodes.find((item) => item.marketKey === communityKey))
  
  const selectCommunity = (communityName) => {
    const commData = data.allCommunities.nodes.find((item) => item.marketKey === communityName)
    setCommunity(commData)
  }

  const commarize = (number, min) => {
    min = min || 1e3

    if (number >= min) {
      const units = ["k", "M", "B", "T"]

      const order = Math.floor(Math.log(number) / Math.log(1000))

      const unitname = units[order - 1]
      const num = Math.floor((number * 10) / 1000 ** order)

      return Math.round(number) + unitname
    }

    return number.toFixed(1).toLocaleString()
  }

  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  return (
    <div className="cha-market__container">
      <h1 className="cha-market__container__title h1-header">
        {marketReportHeader}
      </h1>
      {showLearnMore ? (
        <>
          <div className="cha-market__container__area">
            {marketReportSubheader}
          </div>

          <div className="select-container">
          <select
            onChange={e => selectCommunity(e.target.value)}
            defaultValue={communityKey}
          >
            {data.allCommunities.nodes.map((element, index) => {
              return (
                <option value={element.marketKey} key={index}>
                  {element.name}
                </option>
              )
            })}
          </select>
          </div>
        </>
      ) : null}

      <div className="cha-market__container__inner containers">
        <div className="cha-market__container__row-container">
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              {community.marketResult.communitySalesCount}
            </div>
            <div className="bar"></div>
            <div>Sales number for the last 12 months</div>
          </div>
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              $ {nFormatter(community.marketResult.communityAverageSalesPrice, 1)}
            </div>
            <div className="bar"></div>
            <div>Average sales price for the last 12 months</div>
          </div>
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              ${" "}
              {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(community.marketResult.pricePerSquareFoot)}
            </div>
            <div className="bar"></div>
            <div>Price per square foot</div>
          </div>
        </div>
        <div className="cha-market__container__row-container">
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              {community.marketResult.totalActiveListing}
            </div>
            <div className="bar"></div>
            <div>Current listings</div>
          </div>
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              {community.marketResult.totalPendingListing}
            </div>
            <div className="bar"></div>
            <div>Properties under contract</div>
          </div>
          <div className="cha-market__container__row-container__inner-container">
            <div className="cha-market__container__row-container__title">
              {community.marketResult.totalNewProperties}
            </div>
            <div className="bar"></div>
            <div>New properties on the market</div>
          </div>
        </div>
      </div>
      <div className="market-sub-info">
        {marketReportContent ? <div>{marketReportContent}</div> : ""}

        {showLearnMore && <Link to={`/community/${community.path}`}>Learn More</Link> }
      </div>
      <div className="market-gradient"></div>
    </div>
  )
}

export default Market
